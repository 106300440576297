<template>
  <v-card id="promotion-list">
    <!-- search -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">

      <v-spacer></v-spacer>

      <div class="d-flex align-center pb-5">
        <v-text-field
          v-model="filters.searchQuery"
          single-line
          dense
          outlined
          hide-details
          placeholder="Поиск..."
          class="promotion-list-search me-3"
        ></v-text-field>
      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      :headers="headers"
      :items="branches"
      :server-items-length="totalCount"
      :loading="loading"
      :page.sync="filters.pageNumber"
      :items-per-page.sync="filters.pageSize"
      :sort-by.sync="filters.sortBy"
      :sort-desc.sync="filters.sortDesc"
      class="text-no-wrap"
    >

      <template #[`item.name`]="{item}">
        {{ item.name }}
      </template>

    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapFormHandlers } from '@/helpers/forms'

export default {
  name: 'StreetsList',
  computed: {
    ...mapFormHandlers('street', [
      'filters.pageNumber',
      'filters.pageSize',
      'filters.restaurantId',
      'filters.searchQuery',
      'filters.sortBy',
      'filters.sortDesc',
    ]),
    ...mapState('street', {
      filters: 'filters',
      branches: 'list',
      totalCount: 'totalCount',
      loading: 'loading',
    }),
    headers() {
      return [
        { text: 'Имя', value: 'name' }
      ]
    },
  },
  async created() {
    const { cityId } = this.$route.params
    this.filters.cityId = cityId;
    this.fetchStreets()
  },
  mounted() {
  },
  methods: {
    ...mapActions('street', {
      fetchStreets: 'fetchList'
    }),
  },
}
</script>

<style lang="scss" scoped>
#promotion-list {
  .promotion-list-search {
    max-width: 10.625rem;
  }
}
</style>
