<template>
  <v-card id="promotion-list">
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">
        <v-btn
          color="primary"
          class="me-3"
          @click="syncCities()"
        >
          <span>Синхронизировать</span>
        </v-btn>
      </div>

      <v-spacer></v-spacer>

      <div class="d-flex align-center pb-5">
        <v-text-field
          v-model="filters.searchQuery"
          single-line
          dense
          outlined
          hide-details
          placeholder="Поиск..."
          class="promotion-list-search me-3"
        ></v-text-field>
      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      :headers="headers"
      :items="cities"
      :server-items-length="totalCount"
      :loading="loading"
      :page.sync="filters.pageNumber"
      :items-per-page.sync="filters.pageSize"
      :sort-by.sync="filters.sortBy"
      :sort-desc.sync="filters.sortDesc"
      class="text-no-wrap"
    >

      <template #[`item.name`]="{item}">
        {{ item.name }}
      </template>

      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-center">

          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                :disabled="hasNotPermission('Permissions.DeliveryAreaRead')"
                v-on="on"
                :to="{ name: 'delivery-area', params: { cityId: item.id } }"
              >
                <v-icon size="18">
                  {{ icons.mdiEarth }}
                </v-icon>
              </v-btn>
            </template>
            <span>Зона доставки</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
                :to="{ name: 'street-list', params: { cityId: item.id } }"
              >
                <v-icon size="18">
                  {{ icons.mdiCity }}
                </v-icon>
              </v-btn>
            </template>
            <span>Улицы</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
                @click="syncStreets(item.id)"
              >
                <v-icon size="18">
                  {{ icons.mdiDownload }}
                </v-icon>
              </v-btn>
            </template>
            <span>Синхронизировать улицы</span>
          </v-tooltip>
        </div>
      </template>

    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapFormHandlers } from '@/helpers/forms'
import { hasPermission, toDateTimeString } from '@/helpers'
import StreetList from '../street/street-list'
import {
  mdiEarth,
  mdiCity,
  mdiDownload,
} from '@mdi/js'

export default {
  components: { StreetList },
  name: 'CitiesList',
  data: () => ({ expanded: [], icons: { mdiEarth, mdiCity, mdiDownload } }),
  computed: {
    ...mapFormHandlers('city', [
      'filters.pageNumber',
      'filters.pageSize',
      'filters.searchQuery',
      'filters.sortBy',
      'filters.sortDesc',
    ]),
    ...mapState('city', {
      cities: 'list',
      totalCount: 'totalCount',
      loading: 'loading',
    }),
    headers() {
      return [
        { text: 'Имя', value: 'name' },
        {
          text: 'Действия',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ]
    },
  },
  async created() {
    document.title = 'Города'
    await this.fetchCities()
  },
  methods: {
    ...mapActions('city', {
      fetchCities: 'fetchList',
      syncCities: 'sync',
      syncStreets: 'syncStreets',
    }),
    hasNotPermission(val){
      return !hasPermission(val);
    },
    toTimeSting(val) {
      return toDateTimeString(val)
    },
  },
}
</script>

<style lang="scss" scoped>
#promotion-list {
  .promotion-list-search {
    max-width: 10.625rem;
  }
}
</style>
